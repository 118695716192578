.page {
    margin: 0;
    padding: 0;
    background-image: url('/index/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vh;
}
.wrapper {
    -webkit-backdrop-filter: blur(15.9px);
    backdrop-filter: blur(11px);
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.container {
    backdrop-filter: blur(11px);
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid #f0df73;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    border-radius: 4px;
    width: 454px;
    min-height: 270px;
}

.label {
    margin-bottom: 0;
}

.error {
    color: rgb(250, 36, 70);
    font-family: 'Arcmages-Secondary';
    margin: 0;
    font-size: 0.85em;
    padding: 10px 50px;
    text-align: center;
}


.input {
    display: block;
    width: 280px;
    height: 52px;
    object-fit: contain;
    border-style: solid;
    border-width: 1px;
    opacity: 0.9;
    padding: 0;
    padding-left: 10px;
    margin: 0;
    border-image-source: linear-gradient(107deg, #298a92 19%, #35bac5 84%);
    border-image-slice: 1;
    background-image: linear-gradient(102deg, #1b232c 12%, rgba(19, 19, 19, 1) 87%);
    color: white;
    font-family: 'Arcmages-Secondary';
}

.input.bad {
    border-image-source: none;
    border-image-slice: 0;
    border-color: red;
}

.input.good {
    border-image-source: none;
    border-image-slice: 0;
    border-color: green;
}

.input:focus {
    border-color: #f0df73;
    border-image-source: none;
    border-image-slice: 0;
}
.page {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    min-height: 100vh;
    background-image: url('/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.page .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 51% 47%, rgba(0, 0, 0, 0), #000000 39%, #000000 43%, #000000 56%);
    opacity: 0.66;
}