.button {
    width: 242px;
    height: 68px;
    cursor: 'pointer';
}

@media screen and (max-width: 799px) {
    .button {
        width: calc(242px * 0.85); 
        height: calc(68px * 0.85);
    }
}