.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}

.container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.6;
}

.animation {
    position: absolute;
}

.complete {
    transition: transform 0.3s ease-out, opacity 0.6s ease-out;
    transform: scale(1.5);
    opacity: 0;
}