.container {
    height: 87px;
    max-height: 87px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 3px solid #298a92;
    
    backdrop-filter: blur(10px);
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0) 18%);
}

.container .content {
    max-width: 1120px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container .link {
    width: 156px;
    height: 70px;
    object-fit: contain;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 10px;
    cursor: pointer;
}

.container .link.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.container .first {
    margin-left: -10px;
}

.container .link .bg {
    width: 100%;
    height: 100%;
    position: absolute;
}

.container .link .content {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.container .link .content .icon {
    width: 30px;
    height: 30px;
}


.container .link .content .icon.small {
    width: 26px;
    height: 26px;
}


.container .link .content .label {
    font-size: 1.4em;
    margin-left: 10px;
}

.logout {
    text-decoration: underline;
    color: #f0df73;
    margin-right: 20px;
    font-size: 1.3em;
    cursor: pointer;
}


@media screen and (max-width: 800px) {
    .container .link {
        width: calc(156px * 0.8);
        height: calc(70px * 0.8);
    }
}


@media screen and (max-width: 655px) {
    .container {
        height: 65px;
    }

    .container .link {
        width: calc(156px * 0.7);
        height: calc(70px * 0.7);
    }

    .container .icon {
        display: none;
    }
    
    .container .link .content .label {
        margin-left: 0;
    }
}