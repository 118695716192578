.back {
    width: 50px;
    height: 50px;
    backdrop-filter: blur(20px);
    border: solid 2px #298a92;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.back .arrow {
    transform: rotate(-180deg);
    opacity: 1;
}

.backLabel {
    margin-left: 10px;
    font-size: 1.5em;
}

.characterBox {
    margin-top: 20px;
    width: 317px;
    height: 391px;
    border: solid 2px #298a92;
    background-color: #080A0E;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.playcanvas {
    flex: 1;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
}

.characterBox .name {
    font-size: 2em;
}

.characterBox .title {
    font-size: 1.2em;
    opacity: 0.5;
    margin-bottom: 20px;
}

.categories {
    flex: 1;
    margin-bottom: 50px;
}

.categories .row {
    display: flex;
}

.categories .row:not(:first-of-type) {
    margin-top: 20px;
}

.categories .category {
    width: 111px;
    height: 118px;
    -webkit-backdrop-filter: blur(26px);
    backdrop-filter: blur(26px);
    border: solid 2.6px #298a92;
    background-color: #080A0E;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.categories .category img {
    width: 82px;
    height: 82px;
}

.categories .category p {
    margin: 0;
    margin-top: 5px;
    padding: 0;
    font-size: 1.3em;
}

.assets {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
}

.items {
    width: 80%;
    height: 236px;
    border: solid 1.7px #298a92;
    background-image: linear-gradient(to left, rgba(26, 26, 26, 0.73), rgba(100, 100, 100, 0.14) 18%);
    backdrop-filter: blur(50px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 20px;
}

.items .item {
    width: 86px;
    height: 86px;
    -webkit-backdrop-filter: blur(26px);
    backdrop-filter: blur(26px);
    border: solid 2.6px #298a92;
    background-color: #080A0E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: border 0.2s ease-in-out;
}

.items .item.empty {
    cursor: default;
}

.items .item.selected {
    border: solid 2.6px #f0df73;
}

.items .item:hover {
    border: solid 2.6px #b6a642;
}

.items .item.empty:hover {
    border: solid 2.6px #298a92;
}

.items .item.locked {
    border: solid 2.6px red;
}

.itemGroup {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.colorPickers {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    padding: 0 20px;
}

.items .item .preview {
    width: 80%;
    height: 80%;
}

.savePreview {
    margin-top: 10px;
    max-width: 317px;
    text-align: center;
    font-size: 1.2em;
    font-family: 'Arcmages-Secondary';
    color: #b6a642;
    min-height: 50px;
}

.items .item.selectable {
    cursor: pointer;
}

.picker {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.picker .label {
    font-size: 1.5em;
}

.label1 {
    width: 300px;
    height: 300px;
    margin-top: 50px;
}

.label2 {
    font-size: 2.5em;
    margin: 0;
    margin-top: 30px;
}

.label3 {
    font-size: 1.5em;
    margin: 0;
    margin-top: 20px;
}

.logo {
    position: absolute;
    bottom: 0px;
    right: 10px;
    width: 120px;
    height: 40px;
    opacity: 0.6;
}

.container {
    backdrop-filter: blur(20px);
    border: solid 2px #298a92;
    min-width: 280px;
    max-width: 90%;
    max-height: 400px;
    background-color: rgba(4, 2, 27, 0.4);
    padding: 0px 20px;
    margin: 0px 20px;
}

.info {
    text-align: center;
    font-size: 1em;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5px;
    font-family: "Arcmages-Secondary";
}

.build {
    text-align: center;
    font-size: 4em;
}

.ohno {
    text-align: center;
    font-family: "Arcmages-Secondary";
    font-size: 1.5em;
}

.colorPicker {
    
}

.colorPicker .colorContainer {
    width: 150px;
    height: 150px;
    border: 2px solid #298a92;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    backdrop-filter: blur(20px);
    background-color: rgba(4, 2, 27, 0.4);

    position: relative;
}

.colorPicker .color {
    border: none; 
    background-color: transparent;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    cursor: pointer;
}

.colorPicker .label {
    font-size: 1.5em;
}

input[type="color"] {
	-webkit-appearance: none;
    border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}

input[type="color"]::-webkit-color-swatch {
    border-radius: 50%;
	border: 4px solid #f0df73;
}

input[type="color"]::-moz-color-swatch {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    border: none;
    margin-left: -10px;
	border: 4px solid #f0df73;
}

input[type="color"]::-moz-color-swatch-wrapper {
	padding: 0;
}

input[type="color"]::-moz-color-swatch-wrapper {
	padding: 0;
}

.testx {
    position: absolute;
    width: 36px;
    height: 36px;
}
.testx.invert {
    filter: invert(100%);
}

.input {
    margin-top: 10px;
    height: 36px;
    width: 138px;
    padding-left: 10px;
    max-width: 150px;
    border: 2px solid #f0df73;
    color: white;
    border-image-source: linear-gradient(113deg, #298a92 18%, #35bac5 85%);
    border-image-slice: 1;
    background-image: linear-gradient(106deg, #1b232c 12%, rgba(19, 19, 19, 1) 87%);
    font-family: 'Arcmages';
    font-size: 1.3em;
}

.save {
    cursor: pointer;
    margin-top: 10px;
    height: 52px;
    width: calc(100% - 12px);
    padding-left: 10px;
    border: 2px solid #f0df73;
    color: white;
    background-image: linear-gradient(106deg, #1b232c 12%, rgba(19, 19, 19, 1) 87%);
    font-family: 'Arcmages';
    font-size: 1.8em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.save.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    border: solid 2px #298a92;
}