.container {
    height: calc(100vh - 4px);
    width: 305px;
    transition: right 0.4s ease-in-out, margin-right 0.4s ease-in-out;
    position: relative;
}

.container .x {
    position: absolute;
    left: -6px;
    top: 0;
    width: 10px;
    height: 100vh;
    background: #298a92;
    z-index: 400;
}

.container .x .test {
    position: absolute;
    width: 30px;
    height: 60px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #f0df73;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: 401;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s ease-in-out;
}

.container .x .drawer {
    font-size: 3em;
    color: #606062;
}

.container.real {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid red;
    backdrop-filter: blur(10px);
    border: solid 3px #298a92;
    background-image: linear-gradient(to left, rgba(43, 43, 43, 0.73), rgba(116, 116, 116, 0.14) 18%);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 100vh;
    z-index: 300;
}

.profile {
    margin-top: 8px;
    padding: 10px 10px 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile .avatar {
    width: 58px; 
    height: 58px;
    border-radius: 50%;
    border: 2px solid #298a92;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile .avatarIcon {
    width: 58px; 
    height: 58px;
}

.profile .info {
    flex: 1;
    margin-left: 10px;
}

.profile .info .name {
    font-size: 1.6em;
}

.profile .info .indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #606062;
}

.profile .info .indicator.online {
    background-color: #f0df73;
}

.profile .info .status {
    margin-left: 5px;
    opacity: 0.7;
}

.profile .spellbook {
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    opacity: 0.3;
}

.profile .spellbook .icon {
    width: 48px;
    height: 48px;
}


.profile .spellbook .label {
    font-size: 0.9em;
    margin-top: 5px;
}

.friendsLabel {
    font-size: 2em;
}

.line {
    display: flex;
    flex: 1;
    height: 2px;
    background-color: #606062;
    margin-left: 8px;
    margin-right: 8px;
}

.onlineLabel {
    font-size: 1.5em;
    color: #606062;
}

.actions {
    border-top: 3px solid #298a92;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 67px;
}

.actions .action {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.actions .action .icon {
    width: 26px;
    height: 26px;
}

.actions .action.disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.notice {
    padding: 10px 10px 10px 20px;
    font-family: 'Arcmages-Secondary';
    font-size: 0.9em;
    text-align: center;
}

.friends {
    display: flex;
    flex: 1;
    padding: 10px 10px 10px 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.friends .friend {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.friends .friend .avatar {
    width: 42px; 
    height: 42px;
    border-radius: 50%;
    border: 2px solid #298a92;
    display: flex;
    align-items: center;
    justify-content: center;
}

.friends .friend .avatarIcon {
    width: 42px; 
    height: 42px;
}

.friends .friend .info {
    flex: 1;
    margin-left: 10px;
}

.friends .friend .info .name {
    font-size: 1.4em;
}

.friends .friend .info .indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #606062;
}

.friends .friend .info .indicator.online {
    background-color: #f0df73;
}

.friends .friend .info .status {
    margin-left: 5px;
    opacity: 0.7;
}

@media screen and (max-width: 800px) {

    .container {
        display: none;
    }

    .x {
        display: none;
    }
}
