

body {
  margin: 0;
  font-family: 'Arcmages', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background: black;
}

.fade {
    transition: opacity 0.6s ease-out;
    opacity: 0;
}

.flex {
    display: flex;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.space-around {
    justify-content: space-around;
}

.space-between {
    justify-content: space-between;
}

.grow {
    flex: 1;
}

.fullscreen {
    width: 100vw;
    height: 100vh;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
outline: none;
}

@font-face {
    font-family: 'Arcmages';
    font-weight: bold;
    font-display: auto;
    src: url('/fonts/ArcPrimary-Bold.ttf'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }

@font-face {
    font-family: 'Arcmages';
    font-weight: normal;
    font-display: auto;
    src: url('/fonts/ArcPrimary.ttf'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

@font-face {
    font-family: 'Arcmages-Secondary';
    font-weight: normal;
    font-display: auto;
    src: url('/fonts/ArcSecondary.otf'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}


@media screen and (max-width: 823px) {
    .mobile {
        display: block;
    }

    .web {
        display: none;
    }
}

@media screen and (min-width: 824px) {
    html {
        position: fixed;
        height: 100%;
        overflow: hidden;
    }

    .web {
        display: block;
    }

    .mobile {
        display: none;
    }
}