
.oops {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    min-height: 100vh;
    background-image: url('/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.oops .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 51% 47%, rgba(0, 0, 0, 0), #000000 39%, #000000 43%, #000000 56%);
    opacity: 0.3;
}

.oops .content {
    text-align: center;
    -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        border: solid 2px #298a92;
        min-width: 280px;
        max-width: 90%;
        background-color: rgba(4, 2, 27, 0.4);
        padding: 0px 20px;
        margin: 0px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}

.oops .h {
    font-size: 4em;
}

.oops .info {
    font-size: 1.4em;
}