.page {
    display: flex;
    min-height: 100vh;
    min-width: 100vw;
    background-image: url('/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: none;
}

.connect {
    position: relative;
    display: flex;
    flex: 1;
    background-color: #0c0e13;
    min-width: 660px;
    justify-content: flex-end;
    align-items: center;
}

.connect::before {
    position: absolute;
    content: "";
    background-size: cover;
    background-position: top;
    background-image: url('/connect-bg.png');
    left: 0; right: 0; top: -10px; bottom: 0;
    opacity: 0.2;
}

.connect .content {
    position: absolute;
    width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
}

.connect .content .form {
    width: 290px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.connect .content .form h1 {
    font-size: 2.3em;
}

.connect .content .form h1 span {
    color: #aea257;
}

.connect .content .form a {
    text-align: right;
    color: #36bac5;
    font-family: 'Arcmages-Secondary';
    margin-top: 10px;
    font-size: 0.9em;
    transition: max-height 0.4s ease-in-out, margin-top 0.4s ease-in-out;
    overflow: hidden;
    max-height: 20px;
}

.connect .content .form a.hide {
    max-height: 0px;
    margin-top: 0px;
}

.connect .content .form .switch {
    margin-top: 20px;
    font-size: 1.3em;
    text-align: left;
    font-family: 'Arcmages-Secondary';
}

.formExtras {
    transition: max-height 0.4s ease-in-out;
    max-height: 200px;
    overflow: hidden;
    overflow-x: auto;
}

.formExtras input {
    margin-top: 10px;
    width: calc(100% - 12px);
}

.hidden {
    max-height: 0px;
}


.page input {
    display: block;
    width: 280px;
    height: 52px;
    object-fit: contain;
    border-style: solid;
    border-width: 1px;
    opacity: 0.925;
    padding: 0;
    padding-left: 10px;
    margin: 0;
    margin-top: 20px;
    border-image-source: linear-gradient(107deg, #298a92 9%, #35bac5 84%);
    border-image-slice: 1;
    background-image: linear-gradient(102deg, #1b232c 0%, rgba(19, 19, 19, 1) 87%);
    color: white;
    font-family: 'Arcmages-Secondary';
    transition: margin-top 0.4s ease-in-out;
    display: block;
}

input.registration {
    margin-top: 10px;

}

input.hidden {
    display: none;
}

input.bad {
    border-image-source: none;
    border-image-slice: 0;
    border-color: red;
}

input.good {
    border-image-source: none;
    border-image-slice: 0;
    border-color: green;
}

input:focus {
    border-color: #f0df73;
    border-image-source: none;
    border-image-slice: 0;
}

.submitContainer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.submitContainer span {
    font-size: 0.75em;
    padding-right: 20px;
    color: white;
}

.submitContainer a {
    color: white;
}


.submitContainer .submit {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submitContainer .submit .box {
    width: 90px;
    height: 80px;
}

.submitContainer .submit .arrow {
    position: absolute;
    width: 31px;
    height: 27px;
}

.seperator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Arcmages-Secondary';
    font-size: 1.2em;
    margin-top: 5%;
    flex: 1;
}

.seperator .line {
    width: 2px;
    height: 60px; 
    margin: 10px 0px;
    background-color: #d8d8d8;
    opacity: 0.3;
}

.socials {
    width: 200px;
    margin-top: 30px;
}

.socials .social {
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(107deg, #298a92 19%, #35bac5 84%);
    border-image-slice: 1;
    background-image: linear-gradient(102deg, #1b232c 12%, rgba(19, 19, 19, 1) 87%);
    width: 167px;
    height: 52px;
    color: white;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    margin-top: 20px;
    cursor: pointer;
    text-align: center;
    font-family: 'Arcmages-Secondary';
}

.socials .social.extraPadding {
    padding: 0px 20px;
}

.socials .social:first-of-type {
    margin-top: 0px;
}

.socials .social .svg {
    margin-left: 15px;
    width: 30px;
    height: 30px;
}

.video {
    display: flex;
    flex: 1;
}

.error {
    color: rgb(250, 36, 70);
    font-family: 'Arcmages-Secondary';
    margin: 0;
    font-size: 0.85em;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}




@media screen and (max-width: 900px) {
    .video {
        display: none;
        flex: 0;
        width: 0px;
    }

    .connect {
        display: flex;
        flex: 1 !important;
        justify-content: center !important;
        width: 100vw;
    }

    .web {
        display: flex !important;
    }

    .mobile {
        display: flex !important;
    }

}


@media screen and (max-width: 600px) {
    .connect {
        align-items: center !important;
        justify-content: center !important;
        min-width: 0px !important;
    }

    .content {
        flex-direction: column !important;
        position: relative !important;
    }

    .seperator {
        flex-direction: row !important;
    }

    .seperator .line {
        margin: 0px 10px !important;
        height: 2px !important;
        width: 80px !important;
    }

    .web {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }

    .switch {
        text-align: center !important;
    }

    .socials .social {
        margin: 0;
    }

    .socials .social:not(:first-of-type) {
        margin-left: 20px;
    }

    .socials {
        width: auto !important;
        display: flex;
    }
    
}


@media screen and (min-width: 601px) {
    .mobile {
        display: none !important;
    }

    .web {
        display: auto !important;
    }
}



@media screen and (max-width: 281px) {
    .page input {
        width: 240px !important;
        margin-left: 25px;
    }

    .form {
        width: 100% !important;
        align-items: center !important;
        justify-items: center !important;
        margin: 0;
        padding: 0;
    }

    .form h1 {
        text-align: center;
        font-size: 1.6em !important;
    }
}

@media screen and (max-width: 420px) {
    .socials {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center;
        margin: 0 !important;
    }

    .socials .social {
        margin: 0 !important;
        margin-top: 20px !important;
    }
}
